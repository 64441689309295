import { createHeadSeoData } from '@/assets/js/util/nuxt/seo'

export default {
  head() {
    const routeMeta = this.$nuxt.$options.context.route.meta[0]
    const translationId = routeMeta.translationId || 'home'

    return createHeadSeoData(this.$nuxtI18nHead(), {
      title: this.$t(`meta.${translationId}.title`),
      description: this.$t(`meta.${translationId}.description`),
    })
  },
}
