<template>
  <content-display :facets="['home']" class="p-home">
    <template v-slot:title>
      <main-header />
      <main-slogan />
    </template>
    <template v-slot:default>
      <h2 class="u-reset p-home__sub-title">
        <n-link :to="blogLink" class="u-reset p-home__link">
          {{ $t('meta.blog.title') }}
        </n-link>
      </h2>
      <blog-list :articles="articles" :route-id="$options.entryRouteId" />
    </template>
  </content-display>
</template>

<script>
  import ContentDisplay from '@/components/content-display/content-display.vue'
  import MainHeader from '@/components/main-header/main-header.vue'
  import MainSlogan from '@/components/main-slogan/main-slogan.vue'
  import BlogList from '@/components/blog-list/blog-list.vue'
  import { ROUTE_IDS, ROUTE_LIST } from '@/assets/js/util/nuxt/route'
  import headMixin from '@/assets/js/mixin/head'

  const ROUTE = ROUTE_LIST.find(({ id }) => id === ROUTE_IDS.HOME)

  export default {
    components: { BlogList, MainSlogan, MainHeader, ContentDisplay },
    mixins: [headMixin],
    meta: {
      translationId: ROUTE.translationId,
    },
    // @ts-ignore
    async asyncData({ $content }) {
      const articles = await $content('blog')
        .only(['title', 'slug', 'description', 'publish_date'])
        .sortBy('publish_date', 'desc')
        .limit(2)
        .fetch()

      return {
        articles,
      }
    },
    computed: {
      blogLink() {
        const { name } = ROUTE_LIST.find(({ id }) => id === ROUTE_IDS.BLOG)
        return this.localePath({ name })
      },
    },
    entryRouteId: ROUTE_IDS.BLOG_ENTRY,
  }
</script>

<style lang="scss" src="@/assets/scss/pages/_p-home.scss" />
