import { BASE_URL_WITH_TRAILING_SLASH, SHARING_IMAGE_URL } from '@/assets/js/util/nuxt/seo/util'

export function getTwitterMeta() {
  return [
    { name: 'twitter:site', content: '@ix_werk' },
    { name: 'twitter:card', content: 'summary_large_image' },
    {
      hid: 'twitter:url',
      name: 'twitter:url',
      content: BASE_URL_WITH_TRAILING_SLASH,
    },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: SHARING_IMAGE_URL,
    },
  ]
}
