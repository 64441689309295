import { BASE_URL_WITH_TRAILING_SLASH, SHARING_IMAGE_URL } from '@/assets/js/util/nuxt/seo/util'

export function getFacebookMeta() {
  return [
    {
      hid: 'og:url',
      property: 'og:url',
      content: BASE_URL_WITH_TRAILING_SLASH,
    },
    {
      hid: 'og:type',
      property: 'og:type',
      content: 'website',
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: SHARING_IMAGE_URL,
    },
    {
      hid: 'og:image:width',
      property: 'og:image:width',
      content: '512',
    },
    {
      hid: 'og:image:height',
      property: 'og:image:height',
      content: '512',
    },
  ]
}
