<template>
  <div :class="bemFacets" class="page content-display">
    <header class="page__head">
      <div class="layout layout--primary">
        <div class="layout__container">
          <div class="content-display__head">
            <slot name="title" />
          </div>
        </div>
      </div>
    </header>
    <main class="page__main page__main--fill">
      <div class="layout layout--primary">
        <div class="layout__container">
          <div class="grid grid--primary">
            <div class="content-display__main">
              <slot name="default" />
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="page__foot">
      <div class="content-display__foot" />
    </div>
  </div>
</template>

<script>
  import bemMixin from '@/assets/js/mixin/bem'

  export default {
    mixins: [bemMixin('content-display')],
    props: {
      title: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style lang="scss" src="./_content-display.scss" />
