import {
  BASE_URL,
  BASE_URL_WITH_TRAILING_SLASH,
  SHARING_IMAGE_URL,
} from '@/assets/js/util/nuxt/seo/util'
import { getFacebookMeta } from '@/assets/js/util/nuxt/seo/facebook-meta'
import { getTwitterMeta } from '@/assets/js/util/nuxt/seo/twitter-meta'

export { BASE_URL, BASE_URL_WITH_TRAILING_SLASH, SHARING_IMAGE_URL }

export function createHeadSeoData({ htmlAttrs, meta, link }, { title, description }) {
  return {
    title: `${title} – Interaktionswerk`,
    htmlAttrs: {
      ...htmlAttrs,
    },
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: title,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },

      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: title,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: description,
      },
      ...getFacebookMeta(),
      ...getTwitterMeta(),
      ...meta,
    ],
    link: [...link],
  }
}
