<template>
  <h1 class="u-reset">
    <span class="u-cloak">
      {{ $t('meta.home.title') }}
    </span>
    <ui-icon class="main-header__icon main-header__icon--large" name="interaktionswerk-base">
      <title>
        {{ $t('meta.home.title') }}
      </title>
    </ui-icon>
    <ui-icon class="main-header__icon main-header__icon--small" name="interaktionswerk-wrapped">
      <title>
        {{ $t('meta.home.title') }}
      </title>
    </ui-icon>
  </h1>
</template>

<script>
  import UiIcon from '@/components/ui-icon/ui-icon'

  export default {
    components: { UiIcon },
  }
</script>

<style lang="scss" src="./_main-header.scss" />
