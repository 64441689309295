<template>
  <span class="main-slogan">
    <!-- eslint-disable vue/no-v-html -->
    <n-link
      :to="keywordLink1"
      class="u-reset main-slogan__keyword"
      v-html="$t('main_slogan.keyword_1')"
    />
    <br class="main-slogan__br-mobile" />
    {{ $t('main_slogan.conjunction_1') }}
    <br />
    <n-link
      :to="keywordLink2"
      class="u-reset main-slogan__keyword"
      v-html="$t('main_slogan.keyword_2')"
    />
    <br class="main-slogan__br-mobile" />
    {{ $t('main_slogan.conjunction_2') }}
    <!-- eslint-enable vue/no-v-html -->
  </span>
</template>

<script>
  import { ROUTE_IDS, ROUTE_LIST } from 'assets/js/util/nuxt/route'

  const { name, param } = ROUTE_LIST.find(({ id }) => id === ROUTE_IDS.SERVICES_ENTRY)

  export default {
    computed: {
      keywordLink1() {
        return this.localePath({
          name,
          params: { [param]: this.$t('main_slogan.keyword_1_link_param') },
        })
      },
      keywordLink2() {
        return this.localePath({
          name,
          params: { [param]: this.$t('main_slogan.keyword_2_link_param') },
        })
      },
    },
  }
</script>

<style lang="scss" src="./_main-slogan.scss" />
