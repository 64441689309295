













import Vue from 'vue'
import { format } from 'date-fns'
import { RawLocation } from 'vue-router'
import { ROUTE_LIST } from '@/assets/js/util/nuxt/route'

export default Vue.extend({
  props: {
    articles: {
      type: Array,
      required: true,
    },
    routeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    routeEntry() {
      return ROUTE_LIST.find(({ id }) => id === this.routeId)
    },
  },
  methods: {
    getLink(param: string): RawLocation {
      return this.localePath({ name: this.routeEntry?.name, params: { id: param } })
    },
    getDate(date: string): string {
      return format(new Date(date), 'dd.MM.yyyy')
    },
  },
})
